import React from "react";
import {Loader} from "./Loader";
import {Nav} from "./Nav";
import {Banner} from "./Banner";
import {Cards} from "./Cards";
import {Footer} from "./Footer";
import {Search} from "./Search";

export class ListingPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchInput: {},
        }
        // this.handleLoader = this.handleLoader.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleLoader = () =>{
        console.log('this is an event from Cards when it loads');
    }

    handleSearch = (e) =>{
        // receive search events from the search component and pass into the cards to process
        e.preventDefault();
        e.persist();
        console.log('ListingPage search initiated', e.target.s.value);
        this.setState({searchInput: {'keywords': e.target.s.value, 'language': e.target.l.value, 'year': e.target.y.value}});
    }

    componentDidMount() {
    }

    render() {

        return (

            <div className="App">
                {/*<Loader/>*/}
                <div className="wrapper">
                    <header className="header ">
                        <div className="container-fluid">
                            <Nav/>
                        </div>
                    </header>

                    <Banner/>

                    <Cards searchInput={this.state.searchInput}/>

                    <Footer/>

                </div>
                <Search handleSearch={this.handleSearch}/>
            </div>
        )
    }
}