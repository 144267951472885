import React from 'react';
import Card from './Card';
import Pagination from './Pagination';
import {Loader} from "./Loader";

// The cards component is responsible for responding to the loaded movies and displaying a card for each one

const apiKey = '1e448e0dfcdbb565f5d329820065b4d2'; // This should not be stored in front end JS on a production build. Abstract to a service on a node server or similar
const lang = 'en-us';


export class Cards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayCollection: {},
            currentPage: '',
        };
        this.paginationHandler = this.paginationHandler.bind(this);
    }

    paginationHandler (e) {
        let nextPageNum = e.target.getAttribute('data-getpage');
        // fetch the page of results and re set the state
        fetch("https://api.themoviedb.org/3/movie/top_rated?api_key=" + apiKey + '&language='+lang+'&page='+nextPageNum, {})
            .then(res => {
                return res.json();
            })
            .then(data => {
                this.setState({displayCollection: data, currentPage:nextPageNum});
            })
            .catch(err => {
                console.log("Error: ", err);
            });
    }

    componentDidMount() {
        // First load the movie genres. They are not added into the search returned objects by anything other than id
        // So check if they already exists and retrieve and store them for use throughout the app
        if (this.props.searchInput.keywords || this.props.searchInput.language || this.props.searchInput.year){
            this.searchMovies(this.props.searchInput);
        }else {
            localStorage.getItem('mmGenres')
                ? this.loadDefaultMovies()
                : fetch("https://api.themoviedb.org/3/genre/movie/list?api_key=" + apiKey + '&language=' + lang + '', {})
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        localStorage.setItem("mmGenres", JSON.stringify(res))
                    })
                    .then(this.loadDefaultMovies())
                    .catch(err => {
                        console.log("Error: Could't load genre library", err);
                    });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('Has there been a search', this.props.searchInput);
        // compare the current props with the previous props. No body wants caught in a loop.
        if (this.props.searchInput !== prevProps.searchInput) {
            if (this.props.searchInput.keywords || this.props.searchInput.language || this.props.searchInput.year){
                this.searchMovies(this.props.searchInput);
            }
        }
    }

    searchMovies=(searchInput)=>{
        console.log('Cards searchMovies', searchInput)
         // take the search parameters and create the fetch url
        fetch("https://api.themoviedb.org/3/search/movie?api_key=" + apiKey + "&language="+searchInput.language+"&query="+searchInput.keywords+"&page=1&include_adult=false&primary_release_year="+searchInput.year+"", {})
            // fetch("https://api.themoviedb.org/3/search/movie?api_key=1e448e0dfcdbb565f5d329820065b4d2&language=sq&query=Star&page=1&include_adult=false&year=2018", {})
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({displayCollection: res, currentPage:1});
            })
            .catch(err => {
                console.log("Error: Could't load search results", err);
            });
    }

    loadDefaultMovies=()=>{
        console.log('Cards Load default movies');
        // the default loaded state shows the most popular movies
        fetch("https://api.themoviedb.org/3/movie/top_rated?api_key=" + apiKey + "&language="+lang+"&page=1", {})
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({displayCollection: res, currentPage:1});
            })
            .catch(err => {
                console.log("Error: ", err);
            });
    }

    render() {

        if (this.state.displayCollection.results) {

            return (

                <div className="container">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1525161690102">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner  ">
                                <div className="wpb_wrapper">
                                    <div className="row">

                                        {this.state.displayCollection.results.map((movieData, i) => (
                                            <Card key={'option_' + i} {...movieData} mmGenre={localStorage.getItem('mmGenres')}></Card>
                                        ))}


                                        <Pagination totalPages={this.state.displayCollection.total_pages}
                                                    currentPage={this.state.displayCollection.page}
                                                    paginationHandler={this.paginationHandler}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="container">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1525161690102">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner  ">
                                <div className="wpb_wrapper">
                                    <div className="row">
                                        No Movies found
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}