import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {ListingPage} from './components/ListingPage';
import {DetailPage} from "./components/DetailPage";

function Index() {
    return (
        <ListingPage />
    )
}

function Movie({match}) {
    console.log(match.params.movieId);
    return (
        <DetailPage movieId={match.params.movieId} />
    )
}

function AppRouter() {
    return (
        <Router>
                <Route path="/" exact component={Index} />
                <Route path="/movie/:movieId" component={Movie} />
            {/*</div>*/}
        </Router>
    );
}

export default AppRouter;