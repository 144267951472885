import React from 'react';
// import Card from './Card';
import Pagination from './Pagination';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {Loader} from "./Loader";

// The cards component is responsible for responding to the loaded movies and displaying a card for each one

const apiKey = '1e448e0dfcdbb565f5d329820065b4d2'; // This should not be stored in front end JS on a production build. Abstract to a service on a node server or similar
const lang = 'en';
const imdbURL = "https://www.imdb.com/title/";


export class Detail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayMovie: {},
            isWatched: false,
        };
    }

    componentDidMount() {
        // Load up the required movie gunslinger
        // get the details for the supplied ID
        fetch("https://api.themoviedb.org/3/movie/" + this.props.movieId + '?api_key='+apiKey+'&language='+lang+'', {})
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({displayMovie: res});
            })
            .catch(err => {
                console.log("Error: ", err);
            });
        // does it appear in the watched list stored on this computer?
        // this.mmWatched = JSON.parse(localStorage.getItem('mmWatched'));
        let mmWatched = JSON.parse(localStorage.getItem('mmWatched'));
        let watchStateStyle
        if(mmWatched && mmWatched.indexOf(this.props.movieId) > -1 ) this.setState({isWatched: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.isWatched != prevState.isWatched) {
            console.log('Detail componentDidUpdate', this.props.movieId, this.state.isWatched);
        }
    }

    toggleWatched=(e)=>{
        e.preventDefault();
        let mmWatched = [];
        console.log('The movie is watched',this.state.isWatched);
        console.log(e.target);
        // receive the event from the toggle button change the state and the stored value
        // if there is no local storage for watched movies create it and add or remove the id of this movie
        if(localStorage.getItem('mmWatched')) {
            mmWatched = JSON.parse(localStorage.getItem('mmWatched'));
        }
        // add or remove ( is it already in the mmWatched )
        if(mmWatched && mmWatched.indexOf(this.props.movieId) > -1 ){
            mmWatched.splice(mmWatched.indexOf(this.props.movieId) );
        }else {
            mmWatched.push(this.props.movieId);
        }
        localStorage.setItem('mmWatched', JSON.stringify(mmWatched));
        //
        this.state.isWatched ? this.setState({isWatched:false}) : this.setState({isWatched: true});
    }

    render() {
        if (this.state.displayMovie) {
            return (
                <div className="details">
                    <section className="movie-detail-intro2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="movie-poster">
                                        <img width="300" height="444"
                                             src={"https://image.tmdb.org/t/p/w300_and_h450_bestv2"+this.state.displayMovie.poster_path}
                                             className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                             alt={this.state.displayMovie.title}
                                             srcSet={"https://image.tmdb.org/t/p/w300_and_h450_bestv2"+this.state.displayMovie.poster_path+" 300w, https://image.tmdb.org/t/p/w300_and_h300_bestv2"+this.props.poster_path+" 203w"}
                                             sizes="(max-width: 300px) 100vw, 300px"/>
                                            {/* <a*/}
                                            {/*    href="www.youtube.com/watch?v=jAy6NJ_D5vU" className="play-video">*/}
                                            {/*    <i className="fa fa-play"></i>*/}
                                            {/*</a>*/}
                                    </div>
                                    <div className="movie-details">
                                        <h3 className="title">{this.state.displayMovie.title}</h3>
                                        <ul className="movie-subtext">
                                            {/*<li>54 min</li>*/}
                                            <li>
                                                {this.state.displayMovie.genres &&
                                                this.state.displayMovie.genres.map((e, i) =>
                                                    <span key={i}>{e.name}</span>
                                                )
                                                    .reduce((prev, curr) => [prev, ', ', curr])
                                                }
                                            </li>
                                            <li>{this.state.displayMovie.release_date} ({this.state.displayMovie.production_countries &&
                                            this.state.displayMovie.production_countries.map((e, i) =>
                                                <span key={i}>{e.name}</span>
                                            )
                                                .reduce((prev, curr) => [prev, ', ', curr])
                                            })</li>
                                        </ul>
                                        {this.state.displayMovie.imdb_id &&
                                        <a href={imdbURL+this.state.displayMovie.imdb_id}
                                           className="btn btn-main btn-effect klb"
                                            target="_blank">read more</a>
                                        }
                                        <a href="#details" className="scroll-down btn btn-main btn-effect">details</a>
                                        <a href="#" className={"scroll-down btn btn-main btn-effect "+ (this.state.isWatched ? 'btn-watched' : '')} onClick={this.toggleWatched}>watched</a>
                                        <div className="rating mt10">
                                            <div className="star-rating rating-stars">
                                                <span></span>
                                            </div>
                                            <span>8.7 / 10 </span>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="movie-detail-main ptb100" id="details">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-8 col-sm-12">
                                    <div className="inner pr50">

                                        <div className="storyline">
                                            <h3 className="title">Storyline</h3>
                                            <div className="storyline">
                                                <p>{this.state.displayMovie.overview}</p>
                                            </div>
                                        </div>
                                        {/* No images are available through the API so this section is excluded */}
                                        {/* <div className="movie-media mt50">*/}
                                        {/*    <h3 className="title"> Photos</h3>*/}
                                        {/*    <ul className="image-gallery isotope">*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/landscape-1.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/landscape-1.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-6.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-6.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-5.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-5.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-4.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-4.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/landscape.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/landscape.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-3.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-3.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-2.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-2.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-1.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/bloglist-1.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="element">*/}
                                        {/*            <a href="http://klbtheme.com/movify/wp-content/uploads/2018/04/home2-bg.jpg">*/}
                                        {/*                <img*/}
                                        {/*                    src="http://klbtheme.com/movify/wp-content/uploads/2018/04/home2-bg.jpg"*/}
                                        {/*                    className="img-responsive" alt="Daredevil"/>*/}
                                        {/*            </a>*/}
                                        {/*        </li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>


                                <div className="col-lg-4 col-sm-12">
                                    <div className="sidebar">

                                        <aside className="widget widget-movie-details">
                                            <h3 className="title">Details</h3>
                                            <ul>
                                                <li><strong>Release date: </strong>{this.state.displayMovie.release_date}</li>
                                                <li><strong>Director: </strong>N/A</li>
                                                <li><strong>Imdb Rating: </strong>{this.state.displayMovie.vote_average}</li>
                                                <li><strong>Country: </strong>
                                                    {this.state.displayMovie.production_countries &&
                                                        this.state.displayMovie.production_countries.map((e, i) =>
                                                            <span key={i}>{e.name}</span>
                                                        )
                                                        .reduce((prev, curr) => [prev, ', ', curr])
                                                    }
                                                </li>
                                                <li><strong>Language: </strong>
                                                    {this.state.displayMovie.spoken_languages &&
                                                    this.state.displayMovie.spoken_languages.map((e, i) =>
                                                        <span key={i}>{e.name}</span>
                                                    )
                                                        .reduce((prev, curr) => [prev, ', ', curr])
                                                    }
                                                </li>
                                                <li><strong>Production Co: </strong>
                                                    {this.state.displayMovie.production_companies &&
                                                    this.state.displayMovie.production_companies.map((e, i) =>
                                                        <span key={i}>{e.name}</span>
                                                    )
                                                        .reduce((prev, curr) => [prev, ', ', curr])
                                                    }
                                                </li>
                                            </ul>
                                        </aside>

                                        {/* Cast data not available from the api */}
                                        {/*<aside className="widget widget-movie-cast">*/}
                                        {/*    <h3 className="title">Cast</h3>*/}
                                        {/*    <ul className="cast-wrapper">*/}
                                        {/*        <li>*/}
                                        {/*            <h6 className="name">Charlie Cox</h6>*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            <h6 className="name">Deborah Ann Woll</h6>*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            <h6 className="name">Elden Henson</h6>*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            <h6 className="name">Vincent D'Onofrio</h6>*/}
                                        {/*        </li>*/}
                                        {/*    </ul>*/}
                                        {/*</aside>*/}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>

            );
        }else{
            return (
                <div>No movie here</div>
            )
        }
    }
}