import React from 'react';
import { Link } from 'react-router-dom'

export class Banner extends React.Component {

    render() {

        var divStyle = {
            background: 'url(http://klbtheme.com/movify/wp-content/uploads/2018/04/movie-collection.jpg)',
        };

        return (

            <div data-vc-full-width="true" data-vc-full-width-init="false" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid vc_row-no-padding">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner  ">
                        <div className="wpb_wrapper">
                            <section className="page-header overlay-gradient" style={divStyle}>
                                <div className="container">
                                    <div className="inner"><h2 className="title">Movie Grid 3</h2>
                                        <ol className="breadcrumb">
                                            <li><Link href="/" title="Home"
                                                   rel="bookmark">Home</Link></li>
                                            <li><span>Movie Grid 3</span></li>
                                        </ol>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
}
}