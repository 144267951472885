import React from 'react';

export class Loader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayLoader: 'block'
        }
        let divStyle = {};
    }

    handleAnEvent(e) {
        this.setState({displayLoader: e.target.value})
    }

    render() {
        const divStyle = {
            display: this.props.displayLoader
        };
        return (

            <div className="loading" style={divStyle}>
                <div className="loading-inner">
                    <div className="loading-effect">
                        <div className="object"></div>
                    </div>
                </div>
            </div>
        );
    }
}