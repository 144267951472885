import React, {Component} from 'react';
import './App.scss';
import {Loader} from "./components/Loader";
import {Nav} from "./components/Nav";
import {Banner} from "./components/Banner";
import {Cards} from "./components/Cards";
import {Footer} from "./components/Footer";
import {Search} from "./components/Search";


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // isLoading: false,
            // displayCollection: {}
        };
    }

    componentDidMount = () => {
        // when the app component loads for the first time fetch a page of recent movies
        console.log('App component did mount');
        this.setState({ isLoading: true });

    }

    render() {
        return (
            <div className="App">
                <Loader/>
                <div className="wrapper">

                    <header className="header ">
                        <div className="container-fluid">
                            <Nav/>
                        </div>
                    </header>

                    <Banner/>

                    <Cards displayCollection={this.state.displayCollection} nextPageHandler={this.nextPageHandler} />

                    <Footer/>

                </div>
                <Search/>
            </div>
        );
    }
}

export default App;