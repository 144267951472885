import React from 'react';

export class Footer extends React.Component {

    render() {
        return (

            <footer className="footer1 bg-dark">
                <div className="footer-widget-area ptb100">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget widget-blog widget_text">
                                    <div className="textwidget"><p><img className="alignnone wp-image-93"
                                                                        src="http://klbtheme.com/movify/wp-content/uploads/2018/04/logo-white.png"
                                                                        alt="" width="110" height="28"/></p>
                                        <p className="nomargin">Lorem ipsum dolor sit amet, consectetur adipisicing
                                            elit. Itaque, ducimus, atque. Praesentium suscipit provident explicabo
                                            dignissimos nostrum numquam deserunt earum accusantium et fugit.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget widget-blog widget_tp_widget_recent_tweets"><h4
                                    className="widget-title">Twitter Feed</h4>
                                    <div className="tp_recent_tweets">
                                        <ul>
                                            <li><span>Check out 'Act - Multipurpose Nonprofit Theme' on <a
                                                href="https://twitter.com/search?q=EnvatoMarket"
                                                title="Search #EnvatoMarket" target="_blank">#EnvatoMarket</a> by <a
                                                href="http://twitter.com/SinanIk" title="Follow SinanIk"
                                                target="_blank">@SinanIk</a> <a
                                                href="https://twitter.com/search?q=themeforest"
                                                title="Search #themeforest" target="_blank">#themeforest</a> <a
                                                href="https://t.co/FHGCDkqxOT"
                                                target="_blank">https://t.co/FHGCDkqxOT</a></span><a
                                                className="twitter_time" target="_blank"
                                                href="http://twitter.com/SinanIK/statuses/1125762576900661248">3
                                                days ago</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget widget-blog widget_nav_menu"><h4
                                    className="widget-title">Useful Links</h4>
                                    <div className="menu-footer-top-container">
                                        <ul id="menu-footer-top" className="menu">
                                            <li id="menu-item-587"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-587">
                                                <a href="#">About Movify</a></li>
                                            <li id="menu-item-588"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-588">
                                                <a href="#">Blog</a></li>
                                            <li id="menu-item-590"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-590">
                                                <a href="http://klbtheme.com/movify/contact-us/">Contact Us</a></li>
                                            <li id="menu-item-589"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-589">
                                                <a href="#">Testimonials</a>
                                            </li>
                                            <li id="menu-item-591"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-591">
                                                <a href="#">Error 404</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget widget-blog null-instagram-feed"><h4
                                    className="widget-title">Instagram</h4>
                                    <ul className="instagram-pics instagram-size-small">
                                        <li className=""><a href="#" target="_blank"
                                                            className=""><img
                                            src="//scontent-lax3-1.cdninstagram.com/vp/644375d35d9b8e5adfda8110e2df4698/5D5AC27A/t51.2885-15/e35/s320x320/14031582_742676062549109_960658896_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com"
                                            alt="Instagram Image" title="Instagram Image" className=""/></a></li>
                                        <li className=""><a href="#" target="_blank"
                                                            className=""><img
                                            src="//scontent-lax3-1.cdninstagram.com/vp/eb9a4c2c0a201b5bc97c90f26f7dda13/5D5BABEB/t51.2885-15/e35/s320x320/13687182_1685188491706044_1095878459_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com"
                                            alt="Instagram Image" title="Instagram Image" className=""/></a></li>
                                        <li className=""><a href="#" target="_blank"
                                                            className=""><img
                                            src="//scontent-lax3-1.cdninstagram.com/vp/c06db69428f4cc1f0812275c28f17935/5D6BC5E3/t51.2885-15/e35/s320x320/14052466_191895234557774_1877522217_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com"
                                            alt="Instagram Image" title="Instagram Image" className=""/></a></li>
                                        <li className=""><a href="#" target="_blank"
                                                            className=""><img
                                            src="//scontent-lax3-1.cdninstagram.com/vp/e4f3e64235b458251ebea0fcf57625c8/5D5B1F4F/t51.2885-15/e35/s320x320/13687432_325973357739004_289472593_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com"
                                            alt="Instagram Image" title="Instagram Image" className=""/></a></li>
                                        <li className=""><a href="#" target="_blank"
                                                            className=""><img
                                            src="//scontent-lax3-1.cdninstagram.com/vp/831a447ae0f4b16ac16a4573be842812/5D778385/t51.2885-15/e35/s320x320/14052238_1086283361453259_908455404_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com"
                                            alt="Instagram Image" title="Instagram Image" className=""/></a></li>
                                        <li className=""><a href="#" target="_blank"
                                                            className=""><img
                                            src="//scontent-lax3-1.cdninstagram.com/vp/a2d71006c1ae6cb0b356d821e8fdc295/5D6536F3/t51.2885-15/e35/s320x320/14072956_1194600660592336_2034504495_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com"
                                            alt="Instagram Image" title="Instagram Image" className=""/></a></li>
                                    </ul>
                                    <p className="clear"><a href="#" rel="me"
                                                            target="_blank" className="">Follow Us!</a></p></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="footer-copyright-area ptb30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex">
                                    <div className="links">
                                        <ul id="menu-footer-menu" className="list-inline">
                                            <li className="list-inline-item menu-item menu-item-type-custom menu-item-object-custom">
                                                <a href="#">Privacy &#038; Cookies</a></li>
                                            <li className="list-inline-item menu-item menu-item-type-custom menu-item-object-custom">
                                                <a href="#">Terms &#038; Conditions</a></li>
                                            <li className="list-inline-item menu-item menu-item-type-custom menu-item-object-custom">
                                                <a href="#">Legal Disclaimer</a></li>
                                            <li className="list-inline-item menu-item menu-item-type-custom menu-item-object-custom">
                                                <a href="#">Community</a></li>
                                        </ul>
                                    </div>
                                    <div className="copyright ml-auto">
                                        Copyright 2018.KlbTheme . All rights reserved
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}