import React from "react";
import {Loader} from "./Loader";
import {Nav} from "./Nav";
import {Overlay} from "./Overlay";
import {Detail} from "./Detail";
import {Footer} from "./Footer";
import {Search} from "./Search";

export class DetailPage extends React.Component {

    render() {
        return(
            <div className="App">
                {/*<Loader/>*/}
                <div className="wrapper">
                    <header className="header ">
                        <div className="container-fluid">
                            <Nav/>
                        </div>
                    </header>

                    <Overlay />

                    <Detail movieId={this.props.movieId}/>

                    <Footer/>

                </div>
                <Search/>
            </div>
        )
    }
}