import React from 'react';

export class Overlay extends React.Component {

    render() {

        var divStyle = {
            backgroundImage: 'url(http://klbtheme.com/movify/wp-content/uploads/2018/04/movie-detail-bg.jpg)',
        };

        return (
            <section className="movie-detail-intro overlay-gradient ptb100" style={divStyle}></section>
        );
    }
}