import React from 'react';
import { Link } from 'react-router-dom'
class Card extends React.Component {

    render() {

        const mmGenres = JSON.parse(localStorage.getItem('mmGenres'));
        let genreList = [];

        return (

            <div className="col-md-4 col-sm-6 col-xs-12 xs-mb50">
                <div className="movie-box-3 mb30">
                    <div className="listing-container">
                        <div className="listing-image"><img
                            src={"https://image.tmdb.org/t/p/w300_and_h450_bestv2/"+this.props.poster_path}
                            alt={this.props.title} /></div>
                        <div className="listing-content">
                            <div className="inner">
                                { this.props.video === 'true' ?
                                    <div className="play-btn"><a
                                    href="https://www.youtube.com/watch?v=jAy6NJ_D5vU"
                                    className="play-video"><i
                                    className="fa fa-play"></i></a></div>
                                    : null
                                }
                                <h2 className="title">{this.props.title}</h2>
                                <div className="stars">
                                    <div className="rating"><i
                                        className="fa fa-star"></i><span>{this.props.vote_average} / 10</span>
                                        <span className="category">

                                        {/* the following method seems clumsy - refactor for better efficiency */}
                                        {/* possibility for error exists so only show genres if the mmGenres object exists */}

                                        {this.props.genre_ids && mmGenres.genres &&
                                            // loop over the genre_ids and grab the label for the id from the stored mmGenres object in local storage
                                            this.props.genre_ids.forEach((e, i)=>{
                                                console.log('Loop the genres');

                                                mmGenres.genres.forEach((genre, n) => {
                                                    if (genre.id == e) {
                                                        genreList[n] = genre.name
                                                    }
                                                })
                                            })
                                        }
                                        {genreList.length > 0 &&
                                            genreList.map((e, i) =>
                                                <span key={i}>{e}</span>
                                            )
                                            .reduce((prev, curr) => [prev, ', ', curr])
                                        }
                                        </span>
                                    </div>
                                </div>
                                <p>{this.props.overview && this.props.overview.substring(0, 140)}</p>
                                <Link
                                    to={{
                                        pathname: "/movie/"+this.props.id
                                    }}
                                    className="btn btn-main btn-effect">details</Link>
                                {/*to={{ pathname: "/movie/"+this.props.id, query: { movieId: this.props.id } }}*/}
                                {/*<Link to={"/movie/"+this.props.id} className="btn btn-main btn-effect">details</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Card;