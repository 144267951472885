import React from 'react';

class Pagination extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        // pagination shows 3 items at the most and only shows the prev or next if required
        const showLinksNum = 3;

        if (this.props.totalPages > 0) {
            return (
                <div className="col-md-12">
                    <nav className="pagination">
                        <ul>
                            {this.props.currentPage > 1
                                ? <li key={0} className="page-numbers previous">
                                    <a data-getpage={this.props.currentPage-1}
                                       onClick={this.props.paginationHandler}>
                                        <i data-getpage={this.props.currentPage-1} className="ti-angle-left"></i>
                                    </a>
                                  </li>
                                : null
                            }
                            {
                                [...Array(this.props.totalPages)].slice(this.props.currentPage, this.props.currentPage+showLinksNum).map((e, i) =>

                                    <li key={i + 1}>
                                        <a data-getpage={this.props.currentPage + i} className={'page-numbers' + ((this.props.currentPage + i) == this.props.currentPage ? ' current' : '')}
                                           onClick={this.props.paginationHandler}>{this.props.currentPage + i}</a>
                                    </li>)
                            }
                            {this.props.currentPage < this.props.totalPages-4
                                ? <li key={this.props.currentPage + showLinksNum} className="page-numbers next">
                                    <a data-getpage={this.props.currentPage+1}
                                       onClick={this.props.paginationHandler}>
                                        <i data-getpage={this.props.currentPage+1} className="ti-angle-right"></i>
                                    </a>
                                </li>
                                : null
                            }

                        </ul>
                    </nav>
                </div>
            );
        } else {
            return null;
        }
    };
}

export default Pagination;