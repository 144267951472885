import React from 'react';
import {Link} from "react-router-dom";

export class Nav extends React.Component {

    render() {
        return (

            <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="http://klbtheme.com/movify/"
                   title="Movify &#8211; Movies &amp; Cinema WordPress Theme">
                    <img src="http://klbtheme.com/movify/wp-content/uploads/2018/04/logo.png"
                         alt="Movify &#8211; Movies &amp; Cinema WordPress Theme" className="logo"/>
                    <img src="http://klbtheme.com/movify/wp-content/uploads/2018/04/logo-white.png"
                         alt="Movify &#8211; Movies &amp; Cinema WordPress Theme"
                         className="logo-white"/>
                </a>
                <div className="navbar-collapse" id="main-nav">
                    <ul id="main-menu" className="navbar-nav mx-auto">
                        <li className="nav-item dropdown menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-has-children">
                            <Link
                                className="nav-link dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"
                                to={{
                                    pathname: "/"
                                }}>Home</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav extra-nav">
                        <li className="nav-item">
                            <Link className="nav-link toggle-search" href="#">
                                <i className="fa fa-search"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}